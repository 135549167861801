[
    {
        "id": 1,
        "requestId": "IASTATE-db172efe-de60-44e5-a955-10b2dbab4cc0",
        "requesterFirstName": "John",
        "requesterLastName": "Brown",
        "requesterEmail": "johnbrown@example.com",
        "affilliation": 7,
        "institution": 2,
        "title": "Faculty and Administration Salaries",
        "description": "A public records request.",
        "category": 1,
        "dateInitiated": "1/6/2025",
        "reviewerDateDue": "1/31/2025",
        "dateDue": "1/31/2025",
        "status": 6,
        "state": 2,
        "dateCompleted": "1/31/2025",
        "responseTimeCount": 1,
        "reviewer": ["joedonald", "sueabbot"],
        "counsel": "sueabbot",
        "invoiceAmount": 3,
        "datePaymentSent": "1/31/2025",
        "datePaymentRecieved": "1/31/2025",
        "shepardsNotes": [],
        "notes": [],
        "documents": []
    },
    {
        "id": 2,
        "requestId": "IASTATE-651f5d9d-2fad-4d4a-a96a-72441006cddb",
        "requesterFirstName": "Jodi",
        "requesterLastName": "Brown",
        "requesterEmail": "jodibrown@example.com",
        "affilliation": 6,
        "institution": 1,
        "title": "Copy of Arrest Record",
        "description": "A records request.",
        "category": 1,
        "dateInitiated": "1/1/2025",
        "reviewerDateDue": "1/30/2025",
        "dateDue": "1/31/2025",
        "status": 0,
        "state": 3,
        "dateCompleted": "1/31/2025",
        "responseTimeCount": 1,
        "reviewer": ["joedonald"],
        "counsel": "sueabbot",
        "invoiceAmount": 3,
        "datePaymentSent": "1/31/2025",
        "datePaymentRecieved": "1/31/2025",
        "shepardsNotes": [],
        "notes": [],
        "documents": []
    },
    {
        "id": 3,
        "requestId": "IASTATE-f721ab44-837a-45ba-b1c3-afcb751f2f2f",
        "requesterFirstName": "Sami",
        "requesterLastName": "Smith",
        "requesterEmail": "samismith@example.com",
        "affilliation": 2,
        "institution": 2,
        "title": "Residence halls evictions",
        "description": "Another records request.",
        "category": 1,
        "dateInitiated": "1/1/2025",
        "reviewerDateDue": "1/30/2025",
        "dateDue": "1/31/2025",
        "status": 0,
        "state": 3,
        "dateCompleted": "1/31/2025",
        "responseTimeCount": 1,
        "reviewer": ["joedonald"],
        "counsel": "sueabbot",
        "invoiceAmount": 3,
        "datePaymentSent": "1/31/2025",
        "datePaymentRecieved": "1/31/2025",
        "shepardsNotes": [],
        "notes": [],
        "documents": []
    },
    {
        "id": 4,
        "requestId": "IASTATE-4dafcbd5-cdf4-46e5-86b2-f0a451667723",
        "requesterFirstName": "David",
        "requesterLastName": "Green",
        "requesterEmail": "davidgreen@example.com",
        "affilliation": 1,
        "institution": 2,
        "title": "Copy of the UI custodial training manual",
        "description": "Another records request.",
        "category": 1,
        "dateInitiated": "1/1/2025",
        "reviewerDateDue": "1/30/2025",
        "dateDue": "1/31/2025",
        "status": 0,
        "state": 3,
        "dateCompleted": "1/31/2025",
        "responseTimeCount": 1,
        "reviewer": ["sueabbot"],
        "counsel": "joedonald",
        "invoiceAmount": 3,
        "datePaymentSent": "1/31/2025",
        "datePaymentRecieved": "1/31/2025",
        "shepardsNotes": [],
        "notes": [],
        "documents": []
    },
    {
        "id": 5,
        "requestId": "IASTATE-e8aabf00-afac-4125-a496-7a1f291687bf",
        "requesterFirstName": "Jose",
        "requesterLastName": "Green",
        "requesterEmail": "josegreen@example.com",
        "affilliation": 0,
        "institution": 3,
        "title": "Alumni donation information",
        "description": "Another records request.",
        "category": 2,
        "dateInitiated": "1/1/2025",
        "reviewerDateDue": "1/30/2025",
        "dateDue": "1/30/2025",
        "status": 1,
        "state": 3,
        "dateCompleted": "1/15/2025",
        "responseTimeCount": 1,
        "reviewer": ["joedonald"],
        "counsel": "sueabbot",
        "invoiceAmount": 3,
        "datePaymentSent": "1/31/2025",
        "datePaymentRecieved": "1/31/2025",
        "shepardsNotes": [],
        "notes": [],
        "documents": []
    },
    {
        "id": 6,
        "requestId": "IASTATE-23d78dc7-5acb-4ffb-af72-f2631c10e92e",
        "requesterFirstName": "Brant",
        "requesterLastName": "Brown",
        "requesterEmail": "brantbrown@example.com",
        "affilliation": 3,
        "institution": 3,
        "title": "UIHC Pharmacy Department staff email correspondenc",
        "description": "Another records request.",
        "category": 2,
        "dateInitiated": "1/1/2025",
        "dateCompleted": "1/1/2025",
        "reviewerDateDue": "1/30/2025",
        "dateDue": "1/30/2025",
        "status": 1,
        "state": 3,
        "responseTimeCount": 1,
        "reviewer": ["sueabbot"],
        "counsel": "joedonald",
        "invoiceAmount": 3,
        "datePaymentSent": "1/31/2025",
        "datePaymentRecieved": "1/31/2025",
        "shepardsNotes": [],
        "notes": [],
        "documents": []
    }
]