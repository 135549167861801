import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment.development';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private http = inject(HttpClient);

  usersUrl = environment.apiUrl + environment.usersEndpoint;
  usersRoleUrl = environment.apiUrl + environment.usersRoleEndpoint;

  postUser(form: FormGroup): void {
    try {
      this.http
        .post<User>(this.usersUrl, form.value, {
          observe: 'response',
        })
        .subscribe((res) => {
          console.warn('Status', res.status);
        });
    } catch (e) {
      console.warn('RequestService::postUser: ', e);
    }
  }

  patchUser(form: FormGroup): void {
    try {
      this.http
        .patch<User>(this.usersUrl, form.value, {
          observe: 'response',
        })
        .subscribe((res) => {
          console.warn('Status', res.status);
        });
    } catch (e) {
      console.warn('RequestService::patchUser: ', e);
    }
  }

  deleteUser(form: FormGroup): void {
    try {
      this.http.delete<User>(this.usersUrl, form.value).subscribe((res) => {});
    } catch (e) {
      console.warn('RequestService::deleteUser: ', e);
    }
  }

  // getUser(id: string): Observable<User> {
  //   return this.http.get<User>(this.usersUrl + '/' + id);
  // }

  getUser(): User {
    return {
      username: 'asmith',
      firstName: 'Amy',
      lastName: 'Smith',
      institution: 'UIOWA',
      department: 'CLAS',
      phone: '1231231234',
      alternatePhone: '3213213211',
      email: 'asmith@uiowa.edu',
      alternateEmail: 'example@example.com',
      role: 'admin',
      active: 'true',
    };
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.usersUrl);
  }
}
