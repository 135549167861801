import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment.development';
import { Counsel } from '../../models/counsel';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class CounselService {
  private http = inject(HttpClient);

  requestsUrl = environment.apiUrl + environment.requestsEndpoint;
  counselUrl = environment.apiUrl + environment.counselEndpoint;

  postCounsel(requestId: number, counsel: User): void {
    try {
      this.http
        .post<Counsel>(this.requestsUrl + `/${requestId}/Counsel`, counsel, {
          observe: 'response',
        })
        .subscribe((res) => {
          console.warn('Status', res.status);
        });
    } catch (e) {
      console.warn('RequestService::postCounsel: ', e);
    }
  }

  deleteCounsel(requestId: number, form: FormGroup): void {
    try {
      this.http
        .delete<Counsel>(this.requestsUrl + `/${requestId}/Counsel`, form.value)
        .subscribe((res) => {});
    } catch (e) {
      console.warn('RequestService::deleteCounsel: ', e);
    }
  }

  //real data
  // getCounsel(): Observable<Counsel[]> {
  //   return this.http.get<Counsel[]>(
  //       this.counselUrl
  //   )
  // }

  // test data
  getCounsel(): User[] {
    return [
      {
        username: 'sueabbot',
        firstName: 'Sue',
        lastName: 'Abbot',
        institution: 'Test Institution',
        department: 'Test Department',
        email: 'example@example.com',
        phone: '(555) 555-5555',
        alternateEmail: 'example@example.com',
        alternatePhone: '(555) 555-5555',
        role: '',
        active: '',
      },
      {
        username: 'joedonald',
        firstName: 'Joe',
        lastName: 'Donald',
        institution: 'Test Institution',
        department: 'Test Department',
        email: 'example@example.com',
        phone: '(555) 555-5555',
        alternateEmail: 'example@example.com',
        alternatePhone: '(555) 555-5555',
        role: '',
        active: '',
      },
  ];
  }
}
