import { Component, inject, input } from '@angular/core';
import {
  FormGroup,
  ReactiveFormsModule,
  FormBuilder,
  FormControl,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import {
  MatButtonToggleModule,
} from '@angular/material/button-toggle';
import { Institution } from '../../models/institution';
import { User } from '../../models/user';
import { Category } from '../../models/category';
import { ResponseTimeCount } from '../../models/response-time-count';
import { State } from '../../models/state';
import { Status } from '../../models/status';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { Counsel } from '../../models/counsel';
import { Affilliation } from '../../models/affilliation';

@Component({
  selector: 'app-filter-form',
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatSelectModule,
    MatExpansionModule,
    MatDatepickerModule,
  ],
  templateUrl: './filter-form.component.html',
  styleUrl: './filter-form.component.scss',
})
export class FilterFormComponent {
  fb = inject(FormBuilder);
  institutionData = input<Institution[]>();
  categoryData = input<Category[]>();
  stateData = input<State[]>();
  statusData = input<Status[]>();
  reviewerData = input<User[]>();
  counselData = input<User[]>();
  responseTimeCountData = input<ResponseTimeCount[]>();
  affilliationData = input<Affilliation[]>();
  user = input<User>();

  filterGroup: FormGroup = this.fb.group({
    search: [''],
    status: [''],
    institution: [''],
    responseTimeCount: [''],
    category: [''],
    reviewer: [[]],
    counsel: [''],
    state: [''],
    affilliation: [''],
    dateCompletedStart: new FormControl<Date | null>(null),
    dateCompletedEnd: new FormControl<Date | null>(null),
    dateInitiatedStart: new FormControl<Date | null>(null),
    dateInitiatedEnd: new FormControl<Date | null>(null)
  });
}
