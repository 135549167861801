[
        {
            "value": 0,
            "name": "Iowa Board of Regents"
        },
        {
            "value": 1,
            "name": "The University of Iowa"
        },
        {
            "value": 2,
            "name": "The University of Northern Iowa"
        },
        {
            "value": 3,
            "name": "Iowa State University"
        }
]