<div
  class="margin-top margin-bottom width-md-75 margin-lr-auto elevated-container padding"
>
  @if (state() === "dashboard") {
    <app-dashboard
      [user]="user"
      [data]="requestData"
      [displayedColumns]="requestColumns"
      [institutionData]="institutionData"
      [categoryData]="categoryData"
      [statusData]="statusData"
      [responseTimeCountData]="responseTimeCountData"
      [reviewerData]="reviewerData"
      [counselData]="counselData"
      [stateData]="stateData"
      [affilliationData]="affilliationData"
      (stateChanged)="setState($event)"
      (request)="setRequest($event)"
      [heading]="heading()"
    ></app-dashboard>
  } @else {
    <section class="width-100 margin-lr-auto content">
      <h2 class="padding-top padding-bottom">
        Add a New Public Records Request
      </h2>
      <mat-tab-group class="gray-border">
        <mat-tab label="Request Details">
          @if (state() === "new-request") {
            <app-request-form
              #requestForm
              [institutionData]="institutionData"
              [categoryData]="categoryData"
              [stateData]="stateData"
              [statusData]="statusData"
              [responseTimeCountData]="responseTimeCountData"
            ></app-request-form>
          } @else if (state() === "request-details") {
            <app-request-details [requestId]="requestId"></app-request-details>
          }
        </mat-tab>

        <mat-tab label="Workflow">
          <section class="margin-top content">
            <h3>Selected Reviewers</h3>
            <app-table
              #reviewerTable
              [title]="'reviewers'"
              [data]="reviewers"
              [displayedColumns]="reviewerColumns"
              [canDelete]="true"
            ></app-table>
            <button
              mat-stroked-button
              class="regents margin-sm-top margin-bottom"
              (click)="openDialog()"
            >
              Add Reviewer
            </button>
          </section>

          <section class="margin-top margin-bottom content flex-column">
            <h3>General Counsel</h3>
            <app-table
              #counselTable
              [title]="'counsel'"
              [data]="counsel"
              [displayedColumns]="counselColumns"
              [canDelete]="true"
            ></app-table>

            <mat-form-field class="width-sm-50 margin-sm-top">
              <mat-label>Counsel</mat-label>
              <mat-select name="counsel" [formControl]="counselName">
                @for (counsel of counselData; track counsel.username) {
                  <mat-option [value]="counsel"
                    >{{ counsel.firstName }} {{ counsel.lastName }}</mat-option
                  >
                }
              </mat-select>
            </mat-form-field>

            <button
              mat-stroked-button
              (click)="addCounsel(counselName)"
              class="regents margin-sm-top"
              [disabled]="!counselName.valid"
            >
              Add Counsel
            </button>
          </section>
        </mat-tab>

        <mat-tab label="Payment">
          <section class="margin-top content">
            <h3>Invoices</h3>
            <app-table
              #invoiceTable
              [title]="'invoices'"
              [data]="invoices"
              [displayedColumns]="invoiceColumns"
              [canDelete]="true"
            ></app-table>
            <app-invoice-form
              (addInvoice)="addInvoice($event)"
            ></app-invoice-form>
          </section>
        </mat-tab>
      </mat-tab-group>
    </section>
    <div class="flex-row margin-top margin-bottom content">
      <button
        mat-stroked-button
        type="submit"
        class="regents"
        [disabled]="!requestForm()?.requestGroup?.valid"
        (click)="onSubmit(requestForm()?.requestGroup?.value)"
        extended
      >
        Add Request
      </button>
      <button
        mat-stroked-button
        class="regents margin-left"
        (click)="requestForm()?.requestGroup?.reset()"
      >
        Cancel
      </button>
    </div>
  }
</div>
