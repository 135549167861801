import { Component, inject } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  imports: [ MatSelectModule, MatButtonModule ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  router = inject(Router);

  public login(inst: string) {
    localStorage["X-SignIn-InstitutionHint"] = inst;
    this.router.navigate(['intake']);
  }

}
