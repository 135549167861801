import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment.development';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class ReviewerService {
  private http = inject(HttpClient);

  requestsUrl = environment.apiUrl + environment.requestsEndpoint;
  reviewersUrl = environment.apiUrl + environment.reviewersEndpoint;

  postReviewer(requestId: number, reviewer: User): void {
    try {
      this.http
        .post<User>(this.requestsUrl + `/${requestId}/Reviewers`, reviewer, {
          observe: 'response',
        })
        .subscribe((res) => {
          console.warn('Status', res.status);
        });
    } catch (e) {
      console.warn('RequestService::postReviewer: ', e);
    }
  }

  deleteReviewer(requestId: number, form: FormGroup): void {
    try {
      this.http
        .delete<User>(this.requestsUrl + `/${requestId}/Reviewers`, form.value)
        .subscribe((res) => {});
    } catch (e) {
      console.warn('RequestService::deleteReviewer: ', e);
    }
  }

  // real data
  // getReviewers(): Observable<User[]> {
  //   return this.http.get<User[]>(
  //       this.reviewersUrl
  //   )
  // }

  // test data
  getReviewers(): User[] {
    return [
      {
        username: 'sueabbot',
        firstName: 'Sue',
        lastName: 'Abbot',
        institution: 'Test Institution',
        department: 'Test Department',
        email: 'example@example.com',
        phone: '(555) 555-5555',
        alternateEmail: 'example@example.com',
        alternatePhone: '(555) 555-5555',
        role: '',
        active: '',
      },
      {
        username: 'joedonald',
        firstName: 'Joe',
        lastName: 'Donald',
        institution: 'Test Institution',
        department: 'Test Department',
        email: 'example@example.com',
        phone: '(555) 555-5555',
        alternateEmail: 'example@example.com',
        alternatePhone: '(555) 555-5555',
        role: '',
        active: '',
      },
    ];
  }
}
