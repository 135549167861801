import {
  Component,
  inject,
  input,
  OnInit,
  Signal,
  signal,
  viewChild,
  WritableSignal,
} from '@angular/core';
import { DashboardComponent } from '../shared/components/dashboard/dashboard.component';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RequestFormComponent } from '../shared/components/request-form/request-form.component';
import { RequestDetailsComponent } from '../shared/components/request-details/request-details.component';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { Location } from '@angular/common';
import { TableComponent } from '../shared/components/table/table.component';
import { MatButton } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatOptionModule,
  provideNativeDateAdapter,
} from '@angular/material/core';
import { Invoice } from '../shared/models/invoice';
import { RequestService } from '../shared/services/request/request.service';
import { InvoiceFormComponent } from '../shared/components/invoice-form/invoice-form.component';
import { MatDialog } from '@angular/material/dialog';
import { ReviewerDialogComponent } from '../shared/components/reviewer-dialog/reviewer-dialog.component';
import { Institution } from '../shared/models/institution';
import { Category } from '../shared/models/category';
import { State } from '../shared/models/state';
import { Status } from '../shared/models/status';
import { ResponseTimeCount } from '../shared/models/response-time-count';
import { ReviewerService } from '../shared/services/reviewer/reviewer.service';
import { CounselService } from '../shared/services/counsel/counsel.service';
import { Role } from '../shared/models/role';
import { User } from '../shared/models/user';
import categories from '../../../public/assets/data/categories.json';
import institutions from '../../../public/assets/data/institutions.json';
import roles from '../../../public/assets/data/roles.json';
import states from '../../../public/assets/data/states.json';
import statuses from '../../../public/assets/data/statuses.json';
import affilliations from '../../../public/assets/data/affilliations.json';
import counsel from '../../../public/assets/data/counsel.json';
import reviewers from '../../../public/assets/data/reviewers.json';
import responseTimeCounts from '../../../public/assets/data/response-time-counts.json';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { Request } from '../shared/models/request';
import { UserService } from '../shared/services/user/user.service';
import { Affilliation } from '../shared/models/affilliation';

@Component({
  selector: 'app-intake',
  providers: [provideNativeDateAdapter()],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DashboardComponent,
    RequestFormComponent,
    RequestDetailsComponent,
    MatTab,
    MatTabGroup,
    TableComponent,
    MatButton,
    MatFormFieldModule,
    MatDatepickerModule,
    MatFormFieldModule,
    InvoiceFormComponent,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
  ],
  templateUrl: './intake.component.html',
  styleUrl: './intake.component.scss',
})
export class IntakeComponent {
  id = input<string>();

  invoiceTable = viewChild<TableComponent>('invoiceTable');
  reviewerTable = viewChild<TableComponent>('reviewerTable');
  counselTable = viewChild<TableComponent>('counselTable');
  requestForm = viewChild<RequestFormComponent>('requestForm');

  fb = inject(FormBuilder);
  requestService = inject(RequestService);
  reviewerService = inject(ReviewerService);
  counselService = inject(CounselService);
  userService = inject(UserService);
  private location = inject(Location);
  dialog = inject(MatDialog);

  heading: Signal<string> = signal(
    "Select a request below to view it's details",
  );

  state: WritableSignal<string> = signal('dashboard');

  route: string = this.location.path().split('/')[1];

  counselName = new FormControl<any>(null, Validators.required);

  reviewerColumns: string[] = ['firstName', 'lastName', 'email', 'phone'];

  counselColumns: string[] = ['firstName', 'lastName'];

  invoiceColumns: string[] = ['amount', 'dateSent', 'dateRecieved'];

  requestColumns: string[] = [
    'requester',
    'title',
    'description',
    'dateInitiated',
    'dateDue',
    'institution',
    'state',
    'reviewer',
    'counsel',
    'dateCompleted',
  ];

  requestId: any = '';

  reviewers: User[] = [];

  counsel: User[] = [];

  invoices: Invoice[] = [];

  reviewerData: User[] = [];

  counselData: User[] = [];

  requestData: Request[] = [];

  affilliationData: Affilliation[] = affilliations;

  institutionData: Institution[] = institutions;

  categoryData: Category[] = categories;

  stateData: State[] = states;

  statusData: Status[] = statuses;

  roleData: Role[] = roles;

  responseTimeCountData: ResponseTimeCount[] = responseTimeCounts;

  user: User = new User();

  constructor() {
    this.reviewerData = this.reviewerService.getReviewers();
    this.counselData = this.counselService.getCounsel();
    const requestData = this.requestService.getRequests();
    requestData.forEach((item: any) => {
      item.requester = item.requesterFirstName + ' ' + item.requesterLastName;
    });
    this.requestData = requestData;
    this.user = this.userService.getUser();
  }

  setState(state: string) {
    this.state.set(state);
  }

  setRequest(id: string) {
    this.requestId = id;
  }

  addInvoice(form: FormGroup): void {
    this.invoices.push(form.value);
    this.invoiceTable()?.renderRows();
    form.reset();
  }

  addCounsel(field: FormControl): void {
    this.counsel.push(field.value);
    this.counselTable()?.renderRows();
    this.counselName.reset();
  }

  openDialog() {
    this.dialog
      .open(ReviewerDialogComponent, {
        height: 'auto',
        data: {
          reviewerData: this.reviewerData,
          reviewers: this.reviewers,
          reviewerTable: this.reviewerTable,
          institutionData: this.institutionData,
          roleData: this.roleData,
        },
      })
      .afterClosed()
      .subscribe((result) => {});
  }

  onSubmit(form: FormGroup): void {
    this.requestService.postRequest(
      form,
      this.reviewers,
      this.counsel,
      this.invoices,
    );
  }
}
