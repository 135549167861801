[
    {
        "value": 0,
        "name": "New"
    },
    {
        "value": 1,
        "name": "Awaiting records from reviewer"
    },
    {
        "value": 2,
        "name": "Awaiting legal review"
    },
    {
        "value": 3,
        "name": "Awaiting requester clarfication"
    },
    {
        "value": 4,
        "name": "Awaiting payment"
    },
    {
        "value": 5,
        "name": "No data available"
    },
    {
        "value": 6,
        "name": "Request closed"
    }
]