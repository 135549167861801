<h3 mat-dialog-title class="margin">{{ data().title }}</h3>

<mat-dialog-content class="flex-column">
  <p class="margin-bottom">{{ data().subtitle }}</p>

  <app-user-form
    #userForm
    [reviewers]="data().reviewers"
    [reviewerTable]="data().reviewerTable"
    [reviewerData]="data().reviewerData"
    [institutionData]="data().institutionData"
    [roleData]="data().roleData"
    [state]="data().state"
  ></app-user-form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-stroked-button class="regents" extended matDialogClose>
    Cancel
  </button>

  <button
    mat-stroked-button
    (click)="addUser(userForm.userGroup)"
    class="regents"
    [disabled]="!userForm.userGroup.valid"
    extended
    matDialogClose
  >
    Add Reviewer
  </button>
</mat-dialog-actions>
