<div class="brand-bar-container">
  <mat-toolbar class="margin-lr-auto brand-bar">
    <span class="width-100 margin-sm-top">
      <span class="flex-row flex-start">
        <span class="flex-column center">
          <a href="/" target="_blank">
            <img
              class="logo margin-top"
              src="assets/images/BOR-Logo-1.jpg"
              alt="BOR Logo"
              width="116"
            />
          </a>
        </span>

        <span class="flex-column center site-name-wrapper">
          <a
            href=""
            target="_blank"
            class="site-name flex-column center margin-left"
          >
            <h1 class="white">Public Records Processing</h1>
          </a>
        </span>
      </span>
    </span>
    <span class="flex-row">
      <p class="white margin-right user">{{ accountName }}</p>
      <span class="flex-column center">
        <button mat-button class="logout-button black" (click)="logout()" *ngIf="authenticated; else loginBtn">Log Out</button>
        <ng-template #loginBtn>
          <button mat-button class="logout-button black" (click)="login()">Log in</button>
        </ng-template>
      </span>
    </span>
  </mat-toolbar>
</div>
