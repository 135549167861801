<section class="width-100 margin-lr-auto content">
  <span class="flex-row space-between padding-top padding-bottom">
    <h2>
      {{ heading() }}
    </h2>

    <span class="flex-row flex-end">
      @if (route === "intake" || route === "settings") {
        <button
          mat-flat-button
          (click)="changeState('new-request')"
          class="flex-row space-between secondary-button"
        >
          <mat-icon class="material-symbols-outlined"> add </mat-icon>
          <span class="flex-column center margin-sm-left">
            {{ buttonLabel() }}
          </span>
        </button>

        <button
          mat-stroked-button
          class="regents flex-row space-between margin-sm-left"
        >
          <mat-icon class="material-symbols-outlined"> download </mat-icon>
          <span class="flex-column center margin-sm-left">
            Export Results to Excel
          </span>
        </button>
      }
    </span>
  </span>
  <app-filter-form
    #filterForm
    [institutionData]="institutionData()"
    [categoryData]="categoryData()"
    [statusData]="statusData()"
    [stateData]="stateData()"
    [responseTimeCountData]="responseTimeCountData()"
    [reviewerData]="reviewerData()"
    [counselData]="counselData()"
    [affilliationData]="affilliationData()"
    [user]="user()"
  ></app-filter-form>
  <app-table
    #requestTable
    [data]="data()"
    [displayedColumns]="displayedColumns()"
    (setRequest)="setRequest($event)"
    (getDetails)="changeState($event)"
    [institutionData]="institutionData()"
    [categoryData]="categoryData()"
    [statusData]="statusData()"
    [stateData]="stateData()"
    [responseTimeCountData]="responseTimeCountData()"
    [reviewerData]="reviewerData()"
    [counselData]="counselData()"
    [affilliationData]="affilliationData()"
    [filterGroup]="filterForm.filterGroup"
  ></app-table>
</section>
