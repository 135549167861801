[
    {
        "value": 0,
        "name": "Business/Vendor"
    },
    {
        "value": 1,
        "name": "Iowa Reporter"
    },
    {
        "value": 2,
        "name": "Out of State Reporter"
    },
    {
        "value": 3,
        "name": "Individual/Unknown"
    },
    {
        "value": 4,
        "name": "Student/Employee of Institution"
    },
    {
        "value": 5,
        "name": "Other Colleges"
    },
    {
        "value": 6,
        "name": "Non-Profit Orgs"
    },
    {
        "value": 7,
        "name": "Attorneys"
    },
    {
        "value": 8,
        "name": "Government/Military"
    },
    {
        "value": 9,
        "name": "Labor Unions"
    },
    {
        "value": 10,
        "name": "Other"
    }
]