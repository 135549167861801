<h3 mat-dialog-title class="margin">Select an existing reviewer</h3>

<mat-dialog-content class="flex-column">
  <mat-form-field class="width-100 margin-sm-top">
    <mat-label>Reviewer</mat-label>
    <mat-select name="reviewer" [formControl]="reviewer">
      @for (reviewer of data().reviewerData; track reviewer.username) {
        <mat-option [value]="reviewer"
          >{{ reviewer.firstName }} {{ reviewer.lastName }}</mat-option
        >
      }
    </mat-select>
  </mat-form-field>
  <mat-checkbox class="margin-sm-bottom"
    >Notify selected reviewer via email</mat-checkbox
  >

  <button
    mat-stroked-button
    class="regents"
    (click)="addUser(reviewer.value)"
    [disabled]="!reviewer.valid"
  >
    Add Selected Reviewer
  </button>
  <mat-divider class="margin-top margin-bottom"></mat-divider>
  <h3>Create a new reviewer affiliated with the institution</h3>
  <p class="margin-sm-bottom">
    This person may be selected as a reviwer for future requests.
  </p>
  <button
    mat-stroked-button
    class="regents"
    extended
    (click)="openDialog('affiliated')"
  >
    Create a new institutional reviewer
  </button>

  <mat-divider class="margin-top margin-bottom"></mat-divider>

  <h3>Add a reviewer not affiliated with the institution</h3>
  <p class="margin-sm-bottom">
    This person will only be listed for this request.
  </p>
  <button
    mat-stroked-button
    class="regents"
    extended
    (click)="openDialog('non-affiliated')"
  >
    Add a non-affiliated reviewer
  </button>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button class="regents" extended matDialogClose>
    Cancel
  </button>
</mat-dialog-actions>
