<section>
    <div class="login">
        <div class="login-info">
            <h1>Public Record Request Processing System</h1>
            <hr>
            <p>
                The Public Records Request Processing system allows Board of Regents and member institution staff
                to review and process public records requests.
            </p>
        </div>
        <div class="login-widget">
            <h2>Please log in to continue.</h2>
            <mat-select #institution>
                <mat-option value="uiowa">University of Iowa</mat-option>
                <mat-option value="uni">University of Northwestern Iowa</mat-option>
                <mat-option value="isu">Iowa State University</mat-option>
            </mat-select>
            <button mat-button (click)="login(institution.value)" style="float: right;">Log in</button>
        </div>
    </div>
</section>