import { Component, inject, input, output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDivider } from '@angular/material/divider';
import { User } from '../../models/user';
import { MatTable } from '@angular/material/table';
import { Institution } from '../../models/institution';
import { Role } from '../../models/role';

@Component({
  selector: 'app-user-form',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatLabel,
    MatInput,
    MatDatepickerModule,
    MatFormFieldModule,
    MatButton,
    MatSelect,
    MatOption,
    MatCheckboxModule,
    MatDivider,
  ],
  templateUrl: './user-form.component.html',
  styleUrl: './user-form.component.scss',
})
export class UserFormComponent {
  fb = inject(FormBuilder);

  reviewers = input<User[]>();
  reviewerTable = input<MatTable<User[]>>();
  reviewerData = input<User[]>();
  institutionData = input<Institution[]>();
  roleData = input<Role[]>();
  state = input<string>();

  readonly addUser = output<FormGroup>();

  userGroup = this.fb.group(
    {
      username: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      institution: ['', Validators.required],
      department: ['', Validators.required],

      phone: ['', Validators.required],
      alternatePhone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      alternateEmail: ['', [Validators.required, Validators.email]],

      role: [''],
      active: [''],
    },
    { updateOn: 'blur' },
  );
}
