import { AuthConfig } from "angular-oauth2-oidc";

export const apiConfiguration = {
  apiBaseUrl: "https://publicrecordsrequest-test.iowaregents.edu/api"
};

export const environment = {
  production: false,
  server: 'publicrecordsrequest-staging.iowaregents.edu',
  protocol: 'https://',
  apiUrl: "https://publicrecordsrequest-staging.iowaregents.edu/api/",
  counselEndpoint: "Counsel",
  reviewersEndpoint: "Reviewers",
  rolesEndpoint: "Roles",
  requestsEndpoint: "Requests",
  usersEndpoint: "Users",
  usersRoleEndpoint: "UsersRole",
  categoryEndpoint: "Category",
  stateEndpoint: "State",
  statusEndpoint: "Status",
  responseTimeCountEndpoint: "ResponseTimeCount"
};

export const OAuthConfig: AuthConfig = {
  clientId: "authAngular",
  scope: "openid offline_access",
  responseType: 'code',
  oidc: true,
  skipSubjectCheck: true,
  redirectUri: "https://publicrecordsrequest-test.iowaregents.edu",
  clearHashAfterLogin: true,
  issuer: "https://publicrecordsrequest-test.iowaregents.edu/api",
  tokenEndpoint: "https://publicrecordsrequest-test.iowaregents.edu/connect/token",
  loginUrl: "https://publicrecordsrequest-test.iowaregents.edu/connect/authorize",
  logoutUrl: "https://publicrecordsrequest-test.iowaregents.edu/connect/endsession",
  postLogoutRedirectUri: "https://publicrecordsrequest-test.iowaregents.edu/logged-out"
}