import { Component, input } from '@angular/core';
import { Request } from '../../models/request';

@Component({
  selector: 'app-request-details',
  imports: [],
  templateUrl: './request-details.component.html',
  styleUrl: './request-details.component.scss',
})
export class RequestDetailsComponent {
  requestId = input<any>();
}
