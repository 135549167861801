import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  input,
  OnInit,
  output,
  viewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import {
  MatTable,
  MatTableDataSource,
  MatTableModule,
} from '@angular/material/table';
import { Category } from '../../models/category';
import { Institution } from '../../models/institution';
import { ResponseTimeCount } from '../../models/response-time-count';
import { Status } from '../../models/status';
import { Counsel } from '../../models/counsel';
import { State } from '../../models/state';
import { User } from '../../models/user';
import { Affilliation } from '../../models/affilliation';

@Component({
  selector: 'app-table',
  imports: [
    MatTableModule,
    MatSortModule,
    DatePipe,
    MatIcon,
    MatButton,
    MatPaginatorModule,
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.scss',
})
export class TableComponent implements OnInit, AfterViewInit {
  institutionData = input<Institution[]>();
  categoryData = input<Category[]>();
  statusData = input<Status[]>();
  stateData = input<State[]>();
  counselData = input<User[]>();
  reviewerData = input<User[]>();
    affilliationData = input<Affilliation[]>();
  responseTimeCountData = input<ResponseTimeCount[]>();
  table = viewChild<MatTable<any>>(MatTable);

  title = input<string>();

  data = input<any>();

  getDetails = output<string>();

  setRequest = output<string>();

  canDelete = input<boolean>(false);

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  displayedColumns = input<Array<string>>();

  tableData: Array<any> = [];

  filterGroup = input<any>();

  readonly paginator = viewChild.required(MatPaginator);
  readonly sort = viewChild.required(MatSort);

  ngOnInit() {
    this.tableData = this.data();
    console.log(this.tableData)
    this.dataSource = new MatTableDataSource(this.tableData);

    if (this.canDelete()) {
      this.enableDelete();
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort();
    this.dataSource.paginator = this.paginator();

    const filteredValues = {
      title: '',
      description: '',
      status: '',
      institution: '',
      responseTimeCount: '',
      category: '',
      dateCompleted: '',
      dateInitiated: '',
      state: '',
      reviewer: '',
      counsel: '',
      affilliation: '',
      requester: ''
    };

    this.filterGroup()?.valueChanges.subscribe((form: any) => {
      this.dataSource.filter = JSON.stringify(filteredValues);
    });

    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  changeState(state: string, request: any): void {
    console.log(request);
    this.setRequest.emit(request);
    this.getDetails.emit(state);
  }

  enableDelete(): void {
    this.displayedColumns()?.push('delete');
  }

  camelToTitleCase(str: string) {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
      return str.toUpperCase();
    });
  }

  renderRows() {
    this.table()?.renderRows();
  }

  isDate(value: any): boolean {
    return value instanceof Date;
  }

  isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  getUserName(id: string, column: string): any {
    let result: any;
    if (column === 'counsel') {
      let firstName = this.counselData()?.find((item: any) => item.username === id)?.firstName
      let lastName = this.counselData()?.find((item: any) => item.username === id)?.lastName
      result = firstName + ' ' + lastName    
    }
    return result;
  }

  getReviewerName(reviewers: Array<any>): any {

    let result: any = [];
    reviewers.forEach((reviewer: any) => {
      let firstName = this.reviewerData()?.find((item: any) => item.username === reviewer)?.firstName
      let lastName = this.reviewerData()?.find((item: any) => item.username === reviewer)?.lastName
      result.push(firstName + ' ' + lastName)
    })   
    return result;
  }

  getName(id: number, column: string): string | undefined {
    let result: string | undefined = '';
    if (column === 'institution') {
      result = this.institutionData()?.find(
        (item: any) => item.value === id,
      )?.name;
    }
    if (column === 'status') {
      result = this.statusData()?.find((item: any) => item.value === id)?.name;
    }
    if (column === 'state') {
      result = this.stateData()?.find((item: any) => item.value === id)?.name;
    }
    if (column === 'affilliation') {
      result = this.affilliationData()?.find((item: any) => item.value === id)?.name;
    }
    return result;
  }

  customFilterPredicate() {
    const filterPredicate = (data: any, filter: string): boolean => {
      let match = true;
      if (this.filterGroup().get('search')?.value) {
        match =
          data.title
            .toLowerCase()
            .includes(this.filterGroup().get('search')?.value) ||
          data.description
            .toLowerCase()
            .includes(this.filterGroup().get('search')?.value);
      }
      if (this.filterGroup().get('status')?.value) {
        match =
          match &&
          this.filterGroup()
            .get('status')
            ?.value.toString()
            .includes(data.status);
      }
      if (
        this.filterGroup().get('institution')?.value &&
        this.filterGroup().get('institution')?.value.length > 0
      ) {
        match =
          match &&
          this.filterGroup()
            .get('institution')
            ?.value.toString()
            .includes(data.institution);
      }
      if (
        this.filterGroup().get('responseTimeCount')?.value &&
        this.filterGroup().get('responseTimeCount')?.value.length > 0
      ) {
        match =
          match &&
          this.filterGroup()
            .get('responseTimeCount')
            ?.value.toString()
            .includes(data.responseTimeCount);
      }
      if (
        this.filterGroup().get('category')?.value &&
        this.filterGroup().get('category')?.value.length > 0
      ) {
        match =
          match &&
          this.filterGroup()
            .get('category')
            ?.value.toString()
            .includes(data.category);
      }
      if (
        this.filterGroup().get('reviewer')?.value &&
        this.filterGroup().get('reviewer')?.value.length > 0
      ) {
        match = this.filterGroup()
        .get('reviewer')
        ?.value.some((item: string) => data.reviewer.includes(item));
      }
      if (
        this.filterGroup().get('state')?.value &&
        this.filterGroup().get('state')?.value.length > 0
      ) {
        match =
          match &&
          this.filterGroup()
            .get('state')
            ?.value.toString()
            .includes(data.state);
      }
      if (
        this.filterGroup().get('counsel')?.value &&
        this.filterGroup().get('counsel')?.value.length > 0
      ) {
        match =
          match &&
          this.filterGroup()
            .get('counsel')
            ?.value.toString()
            .includes(data.counsel);
      }
      if (
        this.filterGroup().get('affilliation')?.value &&
        this.filterGroup().get('affilliation')?.value.length > 0
      ) {
        match =
          match &&
          this.filterGroup()
            .get('affilliation')
            ?.value.toString()
            .includes(data.affilliation);
      }
      if (this.filterGroup().get('dateCompletedStart')?.value) {
        match =
          match &&
          this.filterGroup().get('dateCompletedStart')?.value <=
            new Date(data.dateCompleted);
      }
      if (this.filterGroup().get('dateCompletedEnd')?.value) {
        match =
          match &&
          this.filterGroup().get('dateCompletedEnd')?.value >=
            new Date(data.dateCompleted);
      }
      if (this.filterGroup().get('dateInitiatedStart')?.value) {
        match =
          match &&
          this.filterGroup().get('dateInitiatedStart')?.value <=
            new Date(data.dateInitiated);
      }
      if (this.filterGroup().get('dateInitiatedEnd')?.value) {
        match =
          match &&
          this.filterGroup().get('dateInitiatedEnd')?.value >=
            new Date(data.dateInitiated);
      }
      return match;
    };
    return filterPredicate;
  }

  deleteRow(row: any): void {
    this.tableData.splice(this.tableData.indexOf(row), 1);
    this.dataSource = new MatTableDataSource(this.tableData);
    this.renderRows();
  }
}
