@if (data().length > 0) {
  <table mat-table [dataSource]="dataSource" matSort class="loaded-table">
    @for (column of displayedColumns(); track column) {
      <ng-container [matColumnDef]="column">
        <th mat-header-cell *matHeaderCellDef>
          @if (column !== "delete") {
            @if (column === 'state') {
              Internal Status
            } @if (column === 'reviewer') {
              Reviewer(s)
            } @else if (column !== 'state' && column !== 'reviewer') {
              {{ camelToTitleCase(column) }}
            }
          }
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = index"
          [class.small-table-cell]="column === 'delete'"
        >
          @if (column === "delete") {
            <button
              mat-button
              (click)="deleteRow(element)"
              class="flex-row flex-end"
            >
              <mat-icon
                class="material-symbols-outlined margin-lr-auto delete-button"
              >
                close
              </mat-icon>
            </button>
          } @else {
            @if (column === "requester") {
              {{ element[column] }}
              <span class="cool-gray">{{ getName(element["affilliation"], 'affilliation') }}</span>
            } @else if (column === 'counsel') {
              {{ getUserName(element[column], column) }}
            } @else if (column === 'reviewer') {
              {{ getReviewerName(element[column]).join(', ') }}
            } @else if (isDate(element[column])) {
              {{ element[column] | date: "shortDate" }}
            } @else if (isNumber(element[column])) {
              {{ getName(element[column], column) }}
            } @else {
              {{ element[column] }}
            }
          }
        </td>
      </ng-container>
    }
    <tr mat-header-row *matHeaderRowDef="displayedColumns()"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns()"
      (click)="changeState('request-details', row.id)"
    ></tr>
  </table>

  @if (dataSource.filteredData.length === 0) {
    <p class="flex-row center margin-sm-top">
      <b>No results found.</b>
    </p>
  }

  <mat-paginator
    [length]="dataSource.filteredData.length"
    [pageSizeOptions]="[5, 10]"
    [pageSize]="5"
    aria-label="Select page of requests"
    class="margin-top"
  ></mat-paginator>
}
