
[
    {
        "value": "role1",
        "name": "Role 1"
    },
    {
        "value": "role2",
        "name": "Role 2"
    },
    {
        "value": "role3",
        "name": "Role 3"
    }
]