import {
  afterNextRender,
  Component,
  computed,
  inject,
  Injector,
  input,
  output,
  Signal,
  signal,
  TemplateRef,
  ViewChild,
  viewChild,
  WritableSignal,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInput, MatInputModule } from '@angular/material/input';
import { RequestService } from '../../services/request/request.service';
import { MatDivider } from '@angular/material/divider';
import { MatOption, MatSelect } from '@angular/material/select';
import { Institution } from '../../models/institution';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { provideNativeDateAdapter } from '@angular/material/core';
import { Category } from '../../models/category';
import { State } from '../../models/state';
import { Status } from '../../models/status';
import { ResponseTimeCount } from '../../models/response-time-count';

@Component({
  selector: 'app-request-form',
  providers: [provideNativeDateAdapter()],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatLabel,
    MatDivider,
    MatSelect,
    MatOption,
    TextFieldModule,
    MatDatepickerModule,
    MatInputModule,
  ],
  templateUrl: './request-form.component.html',
  styleUrl: './request-form.component.scss',
})
export class RequestFormComponent {
  readonly addRequest = output<FormGroup>();

  requestService = inject(RequestService);
  fb = inject(FormBuilder);

  private _injector = inject(Injector);

  readonly autosize = viewChild.required<CdkTextareaAutosize>('autosize');

  submitted: WritableSignal<boolean> = signal(false);

  enabled: Signal<boolean> = computed(() =>
    this.requestGroup.get('state')?.value === 'closed' ? false : true,
  );

  submit = output<void>();

  institutionData = input<Institution[]>();
  categoryData = input<Category[]>();
  stateData = input<State[]>();
  statusData = input<Status[]>();
  responseTimeCountData = input<ResponseTimeCount[]>();

  requestGroup: FormGroup = this.fb.group(
    {
      requesterFirstName: ['', Validators.required],
      requesterLastName: ['', Validators.required],
      requesterEmail: ['', [Validators.required, Validators.email]],
      affilliation: ['', Validators.required],

      institution: ['', Validators.required],
      title: ['', Validators.required],
      description: ['', [Validators.required, Validators.minLength(10)]],
      category: ['', Validators.required],
      dateInitiated: ['', Validators.required],
      reviewerDateDue: ['', Validators.required],
      dateDue: ['', Validators.required],

      status: ['', Validators.required],
      dateCompleted: ['', Validators.required],
      responseTimeCount: ['', Validators.required],
    },
    { updateOn: 'blur' },
  );

  constructor() {
    this.updateField();
  }

  updateField(field: string = ''): void {
    if (field === 'open' || field === '') {
      this.requestGroup.get('dateCompleted')?.disable();
    } else {
      this.requestGroup.get('dateCompleted')?.enable();
    }
  }

  triggerResize(): void {
    // Wait for content to render, then trigger textarea resize
    afterNextRender(
      () => {
        this.autosize().resizeToFitContent(true);
      },
      {
        injector: this._injector,
      },
    );
  }
}
