<form
  [formGroup]="invoiceGroup"
  class="flex-column margin-top margin-bottom regents"
  (ngSubmit)="addInvoice.emit(invoiceGroup)"
>
  <div class="flex-row space-around">
    <mat-form-field class="width-50">
      <mat-label>Invoice</mat-label>
      <input matInput formControlName="amount" required />
      <mat-error>Required</mat-error>
    </mat-form-field>

    <mat-form-field class="width-sm-25 padding-left">
      <mat-label>Invoice Sent</mat-label>
      <input
        matInput
        formControlName="dateSent"
        [matDatepicker]="dateSentPicker"
      />
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="dateSentPicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #dateSentPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field class="width-sm-25 padding-left">
      <mat-label>Payment Recieved</mat-label>
      <input
        matInput
        formControlName="dateRecieved"
        [matDatepicker]="dateRecievedPicker"
      />
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="dateRecievedPicker"
      ></mat-datepicker-toggle>
      <mat-datepicker #dateRecievedPicker></mat-datepicker>
    </mat-form-field>
  </div>
  <button
    mat-stroked-button
    type="submit"
    class="regents margin-sm-top"
    [disabled]="!invoiceGroup.valid"
    extended
  >
    Add Invoice
  </button>
</form>
