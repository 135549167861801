[
    {
        "value": 0,
        "name": "Ten days or less"
    },
    {
        "value": 1,
        "name": "Eleven to twenty days"
    },
    {
        "value": 2,
        "name": "More than twenty days"
    }
]