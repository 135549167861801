<form class="flex-column space-between" [formGroup]="filterGroup">
  <span class="flex-row flex-end"> Status </span>
  <span class="flex-row space-between">
    <mat-form-field class="width-sm-50">
      <mat-label>Search by title or description</mat-label>
      <input matInput formControlName="search" />
      <mat-icon class="material-symbols-outlined margin-sm-right" matSuffix>
        search
      </mat-icon>
    </mat-form-field>
    @if (user()?.role === "admin") {
      <span class="flex-column flex-end padding-md-bottom">
        <mat-button-toggle-group
          name="status"
          aria-label="Status"
          formControlName="status"
        >
          <mat-button-toggle [value]="[0, 1, 2, 3, 4, 5]"
            >Open</mat-button-toggle
          >
          <mat-button-toggle [value]="6">Closed</mat-button-toggle>
          <mat-button-toggle [value]="[0, 1, 2, 3, 4, 5, 6]"
            >All</mat-button-toggle
          >
        </mat-button-toggle-group>
      </span>
    }
  </span>
  <mat-expansion-panel class="margin-bottom">
    <mat-expansion-panel-header>
      <mat-panel-title> Filters </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-form-field class="width-xs-25 margin-sm-right margin-sm-bottom">
      <mat-label>Date Initiated</mat-label>
      <mat-date-range-input [rangePicker]="dateInitiatedPicker">
        <input
          matStartDate
          formControlName="dateInitiatedStart"
          placeholder="Start date"
        />
        <input
          matEndDate
          formControlName="dateInitiatedEnd"
          placeholder="End date"
        />
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="dateInitiatedPicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #dateInitiatedPicker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field class="width-xs-25 margin-sm-right margin-sm-bottom">
      <mat-label>Date Completed</mat-label>
      <mat-date-range-input [rangePicker]="dateCompletedPicker">
        <input
          matStartDate
          formControlName="dateCompletedStart"
          placeholder="Start date"
        />
        <input
          matEndDate
          formControlName="dateCompletedEnd"
          placeholder="End date"
        />
      </mat-date-range-input>
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle
        matIconSuffix
        [for]="dateCompletedPicker"
      ></mat-datepicker-toggle>
      <mat-date-range-picker #dateCompletedPicker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field class="width-xs-25 margin-sm-right margin-sm-bottom">
      <mat-label>Institution</mat-label>
      <mat-select formControlName="institution" name="institution" multiple>
        @for (institution of institutionData(); track institution.value) {
          <mat-option [value]="institution.value">{{
            institution.name
          }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="width-xs-25 margin-sm-right margin-sm-bottom">
      <mat-label>Internal Status</mat-label>
      <mat-select formControlName="state" name="state" multiple>
        @for (state of stateData(); track state.value) {
          <mat-option [value]="state.value">{{ state.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="width-xs-25 margin-sm-right margin-sm-bottom">
      <mat-label>Response Time Count</mat-label>
      <mat-select
        formControlName="responseTimeCount"
        name="responseTimeCount"
        multiple
      >
        @for (count of responseTimeCountData(); track count.value) {
          <mat-option [value]="count.value">{{ count.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="width-xs-25 margin-sm-right margin-sm-bottom">
      <mat-label>Category</mat-label>
      <mat-select formControlName="category" name="category" multiple>
        @for (category of categoryData(); track category.value) {
          <mat-option [value]="category.value">{{ category.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="width-xs-25 margin-sm-right margin-sm-bottom">
      <mat-label>Affilliation</mat-label>
      <mat-select formControlName="affilliation" name="affilliation" multiple>
        @for (affilliation of affilliationData(); track affilliation.value) {
          <mat-option [value]="affilliation.value">{{ affilliation.name }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="width-xs-25 margin-sm-right margin-sm-bottom">
      <mat-label>Reviewer</mat-label>
      <mat-select formControlName="reviewer" name="reviewer" multiple>
        @for (reviewer of reviewerData(); track reviewer.username) {
          <mat-option [value]="reviewer.username">{{ reviewer.firstName }} {{ reviewer.lastName }}</mat-option>
        }
      </mat-select>
    </mat-form-field>

    <mat-form-field class="width-xs-25 margin-sm-right margin-sm-bottom">
      <mat-label>Counsel</mat-label>
      <mat-select name="counsel" formControlName="counsel" multiple>
        @for (counsel of counselData(); track counsel.username) {
          <mat-option [value]="counsel.username"
            >{{ counsel.firstName }} {{ counsel.lastName }}</mat-option
          >
        }
      </mat-select>
    </mat-form-field>

    <button mat-button class="regents margin-left" (click)="filterGroup.reset()">
      Clear All Filters
    </button>
  </mat-expansion-panel>
</form>
