[
    {
        "value": 0,
        "name": "Academic Affairs"
    },
    {
        "value": 1,
        "name": "Administration"
    },
    {
        "value": 2,
        "name": "Athletics"
    },
    {
        "value": 3,
        "name": "External Relations"
    },
    {
        "value": 4,
        "name": "Facilities"
    },
    {
        "value": 5,
        "name": "Finance and Operations"
    },
    {
        "value": 6,
        "name": "Healthcare"
    },
    {
        "value": 7,
        "name": "Personnel"
    },
    {
        "value": 8,
        "name": "Procurement"
    },
    {
        "value": 9,
        "name": "Public Safety"
    },
    {
        "value": 10,
        "name": "Research"
    },
    {
        "value": 11,
        "name": "Student Services"
    },
    {
        "value": 12,
        "name": "Other"
    }
]