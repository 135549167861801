import {
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  output,
  Signal,
  viewChild,
} from '@angular/core';
import { TableComponent } from '../table/table.component';
import { MatButton } from '@angular/material/button';
import { Location } from '@angular/common';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatIconModule } from '@angular/material/icon';
import { User } from '../../models/user';
import { Category } from '../../models/category';
import { Institution } from '../../models/institution';
import { ResponseTimeCount } from '../../models/response-time-count';
import { State } from '../../models/state';
import { Status } from '../../models/status';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { FilterFormComponent } from '../filter-form/filter-form.component';
import { Counsel } from '../../models/counsel';
import { Affilliation } from '../../models/affilliation';

@Component({
  selector: 'app-dashboard',
  imports: [
    TableComponent,
    MatButton,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonToggleModule,
    MatSelectModule,
    MatExpansionModule,
    FilterFormComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss',
})
export class DashboardComponent {
  institutionData = input<Institution[]>();
  categoryData = input<Category[]>();
  statusData = input<Status[]>();
  stateData = input<State[]>();
  reviewerData = input<User[]>();
  counselData = input<User[]>();
  affilliationData = input<Affilliation[]>();
  responseTimeCountData = input<ResponseTimeCount[]>();
  requestTable = viewChild<TableComponent>('requestTable');
  filterForm = viewChild<FilterFormComponent>('filterForm');
  private location = inject(Location);

  stateChanged = output<string>();

  request = output<string>();

  route: string = this.location.path().split('/')[1];

  buttonLabel: Signal<string> = computed(() =>
    this.route === 'intake' ? 'Add a New Request' : 'Add a New User',
  );

  searched: boolean = false;

  displayedColumns = input<Array<string>>();

  data = input<any>();

  heading = input<any>();

  user = input<User>();

  changeState(state: string): void {
    console.log(state);
    this.stateChanged.emit(state);
  }

  setRequest(request: string): void {
    console.log(request);
    this.request.emit(request);
  }
}
