export class User {
  public username: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public institution: string = '';
  public department: string = '';
  public phone: string = '';
  public alternatePhone: string = '';
  public email: string = '';
  public alternateEmail: string = '';
  public role: string = '';
  public active: string = '';
}
