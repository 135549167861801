import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { CommunityAuthService } from '../../services/authorization/community-auth.service';
import { OAuthConfig } from '../../../../environments/environment';

//([a-ln-zA-LN-Z].*|m[b-zB-Z].*|ma[a-rT-ZA-RT-Z].*|mas[a-su-zA-SU-Z].*|mast[a-df-zA-DF-Z].*|maste[a-qs-zA-QS-Z].*)
@Injectable()
export class AuthGuard {
  private oauthService = inject(OAuthService);
  private authService = inject(CommunityAuthService);
  private router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    this.oauthService.configure(OAuthConfig);
    //this.oauthService.events.subscribe(e => console.log("event, e", e));
    if (localStorage["X-SignIn-InstitutionHint"]) {
      this.oauthService.customQueryParams = {
        "whom": localStorage["X-SignIn-InstitutionHint"]
      };
      return from(this.authService.runInitialLoginSequence())
        .pipe(
          switchMap(_ => this.authService.isDoneLoading$),
          filter(isDone => isDone),
          switchMap(_ => this.authService.isAuthenticated$),
          tap(isAuth => { if (!isAuth) this.authService.login(state.url); })
        );
    } else {
      this.router.navigate(['/login']);
      return of(false);
    }
  }
}
