import { Component, inject } from '@angular/core';
import { TabsComponent } from '../tabs/tabs.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { CommunityAuthService } from '../../services/authorization/community-auth.service';
import { delay, distinctUntilChanged, tap } from 'rxjs';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-brand-bar',
  imports: [MatToolbarModule, MatButtonModule, CommonModule, FormsModule, ReactiveFormsModule,],
  templateUrl: './brand-bar.component.html',
  styleUrl: './brand-bar.component.scss',
})
export class BrandBarComponent {
  public authenticated: boolean = false;
  isImpersonating: boolean | undefined = false;
  public accountName: string | undefined = undefined;
  authService = inject(CommunityAuthService);
  router = inject(Router);
  
  ngOnInit() {
    this.authService.isAuthenticated$.pipe(
      delay(25), // enough to prevent "Expression has changed after it was checked."
      distinctUntilChanged(),
      tap(this.authChange.bind(this))
    ).subscribe();
  }

  authChange(a: boolean) {
    console.log("auth changed", a, this.authService.actualUsername)
    this.authenticated = a;
    this.isImpersonating = this.authService.isImpersonating;
    this.accountName = this.authService.actualUsername;
  }

  public logout() {
    delete localStorage["X-SignIn-InstitutionHint"];
    this.authService.logout();
    this.router.navigate(['logged-out']);
  }

  public login() {
    this.router.navigate(['login']);
  }
}
