import { Component, inject, signal, WritableSignal } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { DashboardComponent } from '../shared/components/dashboard/dashboard.component';
import { UserDetailsComponent } from '../shared/components/user-details/user-details.component';
import { Location } from '@angular/common';
import { UserFormComponent } from '../shared/components/user-form/user-form.component';
@Component({
  selector: 'app-settings',
  imports: [
    MatTabsModule,
    DashboardComponent,
    UserFormComponent,
    UserDetailsComponent,
    UserFormComponent,
  ],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent {
  private location = inject(Location);

  state: WritableSignal<string> = signal('dashboard');

  route: string = this.location.path().split('/')[1];
}
