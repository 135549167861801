import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { combineLatestAll, filter, map } from 'rxjs/operators';

// Isn't it silly to try to make one of these auth services on our own? So, we won't.
// https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/blob/master/src/app/core/auth.service.ts
@Injectable({ providedIn: 'root' })
export class CommunityAuthService {

  constructor(
    private _oauthService: OAuthService,
    private router: Router,
  ) {
    // dev only
    // console.log('identities', this.identityClaims);
    // Useful for debugging:
    this._oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error(event);
      } else {
        //console.warn(event);
      }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup.
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      const isAuthed = this._oauthService.hasValidAccessToken();
      this.isAuthenticatedSubject$.next(isAuthed);
      if (isAuthed) {
        this.loadUserProfile();
      }
      if (!this._oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this._oauthService.events
      .subscribe(_ => {
        this.isAuthenticatedSubject$.next(this._oauthService.hasValidAccessToken());
      });

    this._oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(this.loadUserProfile.bind(this));

    this._oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => this.navigateToLoginPage());

    this._oauthService.setupAutomaticSilentRefresh();
    this.isAuthenticatedSubject$ = new BehaviorSubject<boolean>(this.isAuthenticated());
    this.isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
    // this.profile = 
    this.canActivateProtectedRoutes$ = combineLatest([
      this.isAuthenticated$,
      this.isDoneLoading$
    ]).pipe(map(values => values.every(b => b)));

    combineLatest([this.isAuthenticated$, this.isProfileLoaded$, this.isDoneLoading$])
    .subscribe(_ => {
      // Update properties on every event.
      this.isImpersonating = !!sessionStorage[this.impersonationKey];
      this.impersonatedHawkId = this.isImpersonating ? sessionStorage[this.impersonationKey] : null;
      const claims = this._oauthService?.getIdentityClaims();
      this.actualUsername = this.profile && this.profile["sub"];
      this.applicationUsername = this.isImpersonating ? this.impersonatedHawkId : this.actualUsername;
      console.log(`Setting isImpersonating=${this.isImpersonating}, impersonatedHawkId=${this.impersonatedHawkId}, actualUsername=${this.actualUsername}, applicationUsername=${this.applicationUsername}`, this);
    });
  }

    // ITS-added:
  impersonationKey = 'impersonatedHawkId';
  public isImpersonating: boolean | undefined;
  public impersonatedHawkId: string | undefined;
  public applicationUsername: string | undefined;
  public actualUsername: string | undefined;

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() { return this._oauthService.getAccessToken(); }
  public get refreshToken() { return this._oauthService.getRefreshToken(); }
  public get identityClaims() { return this._oauthService?.getIdentityClaims(); }
  public get idToken() { return this._oauthService.getIdToken(); }
  public get logoutUrl() { return this._oauthService.logoutUrl; }

  private isAuthenticatedSubject$: BehaviorSubject<boolean>;
  public isAuthenticated$: Observable<boolean>;
  public isAuthenticated() { return this._oauthService.hasValidAccessToken(); }

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private isImpersonatedSubject$ = new BehaviorSubject<boolean>(false);
  public isImpersonated = this.isImpersonatedSubject$.asObservable();

  private isProfileLoadedSubject$ = new BehaviorSubject<boolean>(false);
  public isProfileLoaded$: Observable<boolean> = this.isProfileLoadedSubject$;
  public profile: { [key: string]: any } | undefined;


  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean>;

  private async loadUserProfile() {
    if (!this.isAuthenticated()) {
      this.isProfileLoadedSubject$.next(false);
      return;
    }
    if (!! sessionStorage["user-profile"]) {
      this.profile = JSON.parse(sessionStorage["user-profile"]);
      console.log(this.profile);
      this.isProfileLoadedSubject$.next(true);
      return;
    }

    try {
      this.profile = (<any>await this._oauthService.loadUserProfile())?.info;
      console.log(this.profile);
      sessionStorage["user-profile"] = JSON.stringify(this.profile);
      this.isProfileLoadedSubject$.next(true);
    } catch(err) {
        console.log("here", this.isAuthenticated(), sessionStorage["user-profile"], err);
      this.isProfileLoadedSubject$.next(false);
    }
  };

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.router.navigateByUrl('/should-login');
  }

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      console.log('Encountered hash fragment, plotting as table...');
      console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return this._oauthService.loadDiscoveryDocument()
      // 1. HASH LOGIN:
      // Try to log in via hash fragment after redirect back
      // from IdServer from initImplicitFlow:
      .then(() => this._oauthService.tryLoginCodeFlow())
      .then(() => {
        if (this._oauthService.hasValidAccessToken()) {
          this.loadUserProfile();
          return Promise.resolve();
        }

        // We can't handle the truth, just pass on the problem to the
        // next handler.
        return Promise.reject("No valid access token.");
      })

      .then(() => {
        this.isDoneLoadingSubject$.next(true);

        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this._oauthService.state && this._oauthService.state !== 'undefined' && this._oauthService.state !== 'null') {
          let stateUrl = this._oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          console.debug(`There was state of ${this._oauthService.state}, so we are sending you to: ${stateUrl}`);
          this.router.navigateByUrl(stateUrl);
        }
      })
      .catch(err => {
        console.warn(err, arguments);
        this.isDoneLoadingSubject$.next(true)
      });
  }

  public login(targetUrl?: string, noState?: boolean) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    if (! noState) {
      this._oauthService.initCodeFlow(targetUrl || this.router.url);
      return;
    }

    this._oauthService.initCodeFlow();
  }

  public logout() { 
    this._oauthService.revokeTokenAndLogout(undefined, false);
    this._oauthService.logOut();
  }
  
  public refresh() { this._oauthService.silentRefresh(); }
  public hasValidToken() { return this._oauthService.hasValidAccessToken(); }

  public hawkId: string | undefined;
  public endImpersonation() {
    sessionStorage.removeItem(this.impersonationKey);
    this.isImpersonatedSubject$.next(false);
  }
  public impersonate(hawkId: string) {
    sessionStorage[this.impersonationKey] = hawkId;
    this.isImpersonatedSubject$.next(true);
  }
}
