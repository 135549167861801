import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../../environments/environment.development';
import { Request } from '../../models/request';
import { Invoice } from '../../models/invoice';
import { InvoiceService } from '../invoice/invoice.service';
import { Counsel } from '../../models/counsel';
import { CounselService } from '../counsel/counsel.service';
import { ReviewerService } from '../reviewer/reviewer.service';
import { User } from '../../models/user';
import requests from '../../../../../public/assets/data/requests.json';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  private http = inject(HttpClient);
  invoiceService = inject(InvoiceService);
  reviewerService = inject(ReviewerService);
  counselService = inject(CounselService);

  requestsUrl = environment.apiUrl + environment.requestsEndpoint;

  postRequest(
    requestForm: FormGroup<any> | undefined,
    reviewers: User[] = [],
    counsel: User[] = [],
    invoices: Invoice[] = [],
  ): void {
    try {
      console.log('form', requestForm);
      console.log('reviewers', reviewers);
      console.log('counsel', counsel);
      console.log('invoices', invoices);
      this.http
        .post<Request>(this.requestsUrl, requestForm?.value, {
          observe: 'response',
        })
        .subscribe((res) => {
          // Get request ID from response (or elsewhere) and post temp table data to /Reviewers, /Counsel, and /Invoices
          const requestId = 0;

          reviewers.forEach((item: User) => {
            this.reviewerService.postReviewer(requestId, item);
          });

          counsel.forEach((item: User) => {
            this.counselService.postCounsel(requestId, item);
          });

          invoices.forEach((item: Invoice) => {
            this.invoiceService.postInvoice(requestId, item);
          });

          console.warn('Status', res.status);
        });
    } catch (e) {
      console.warn('RequestService::postRequest: ', e);
    }
  }

  patchRequest(form: FormGroup): void {
    try {
      this.http
        .patch<Request>(this.requestsUrl, form.value, {
          observe: 'response',
        })
        .subscribe((res) => {
          console.warn('Status', res.status);
        });
    } catch (e) {
      console.warn('RequestService::patchRequest: ', e);
    }
  }

  deleteRequest(form: FormGroup): void {
    try {
      this.http
        .delete<Request>(this.requestsUrl, form.value)
        .subscribe((res) => {});
    } catch (e) {
      console.warn('RequestService::deleteRequest: ', e);
    }
  }

  // getRequest(id: string): Observable<Request> {
  //   return this.http.get<Request>(this.requestsUrl + '/' + id);
  // }

  getRequest(id: string | undefined): any {
    return requests.find((item: any) => item.id === id);
  }

  // getRequests(): Observable<Request[]> {
  //   return this.http.get<Request[]>(this.requestsUrl);
  // }

  // test data
  getRequests(): Request[] {
    return requests;
  }
}
