import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment.development';
import { Invoice } from '../../models/invoice';

@Injectable({
  providedIn: 'root',
})
export class InvoiceService {
  private http = inject(HttpClient);

  requestsUrl = environment.apiUrl + environment.requestsEndpoint;

  postInvoice(requestId: number, invoice: Invoice): void {
    try {
      this.http
        .post<Invoice>(this.requestsUrl + `/${requestId}/Invoices`, invoice, {
          observe: 'response',
        })
        .subscribe((res) => {
          console.warn('Status', res.status);
        });
    } catch (e) {
      console.warn('RequestService::postInvoice: ', e);
    }
  }

  deleteInvoice(requestId: number, invoiceId: number, form: FormGroup): void {
    try {
      this.http
        .delete<Invoice>(
          this.requestsUrl + `/${requestId}/Invoices/${invoiceId}`,
          form.value,
        )
        .subscribe((res) => {});
    } catch (e) {
      console.warn('RequestService::deleteInvoice: ', e);
    }
  }

  getInvoice(requestId: number): Observable<Invoice[]> {
    return this.http.get<Invoice[]>(
      this.requestsUrl + `/${requestId}/Invoices`,
    );
  }
}
