import { Component, inject, output } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatFormField,
  MatFormFieldModule,
  MatLabel,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-invoice-form',
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatLabel,
    MatInput,
    MatDatepickerModule,
    MatFormFieldModule,
    MatButton,
  ],
  templateUrl: './invoice-form.component.html',
  styleUrl: './invoice-form.component.scss',
})
export class InvoiceFormComponent {
  fb = inject(FormBuilder);

  readonly addInvoice = output<FormGroup>();

  invoiceGroup: FormGroup = this.fb.group(
    {
      amount: ['', Validators.required],
      dateSent: ['', Validators.required],
      dateRecieved: ['', Validators.required],
    },
    { updateOn: 'blur' },
  );
}
